import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeFourComponent } from "./components/home-four/home-four.component";
import { SuccessComponent } from "./success/success.component";

const routes: Routes = [
    { path: "", component: HomeFourComponent },
    { path: "success", component: SuccessComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
