<section id="details" class="property-details-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="video-image">
                    <img src="assets/img/video-bg.jpg" alt="image">

                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="property-details-info">
                    <div class="property-details">
                        <h3>Property Details</h3>

                        <ul>
                            <li>Listing Type: <span>Single Or Group</span></li>
                            <li>Pool: Heated<span>32 m<sup>2</sup></span></li>
                            <li>Bm<sup>3</sup>hrooms: <span>2</span></li>
                            <li>House Size: <span>120 m<sup>2</sup></span></li>
                            <li>Parking Size: <span>120 m<sup>2</sup></span></li>
                            <li>Canopy Size: <span>40 m<sup>2</sup></span></li>
                            <li>Year Built: <span>1911</span></li>
                            <li>Tazes: <span>18252.0</span></li>
                            <li>Tax Year: <span>2018</span></li>
                        </ul>
                    </div>

                    <div class="additional-details">
                        <h3>Additional Details</h3>
                        
                        <ul>
                            <li>Fuel: <span>Electric, Gas</span></li>
                            <li>Construction Type: <span>Brick, Frame</span></li>
                            <li>Fireplace Location: <span>Bedrooom</span></li>
                            <li>Roof: <span>Composition Shingles</span></li>
                            <li>Fireplace: <span>Wood</span></li>
                            <li>Lot Size: <span>8,276.00 sqft</span></li>
                            <li>Interitor Features: <span>Kitchen</span></li>
                            <li>Road Surface: <span>Paven Road</span></li>
                            <li>Sale Rent: <span>For Sale</span></li>
                            <li>Seller: <span>Individual</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>