<section id="feedback" class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{'PRICES.TITLE'| translate}}</h2>
            <p>{{'PRICES.INTRO'| translate}}</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer4.jpg" alt="image">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT1' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">240&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer1.jpg" alt="image">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT2' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">240&#8364; - 290&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer2.jpg" alt="image">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT3' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">290&#8364; - 340&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer3.jpg" alt="image">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT4' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">340&#8364; - 460&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer5.jpg" alt="image" height="145" width="145">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT5' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">440&#8364; - 460&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>‚
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer6.jpg" alt="image" width="145" height="145">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT6' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">240&#8364; - 295&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>‚
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-feedback">
                    <div class="client-image">
                        <img src="assets/img/offer7.jpg" alt="image"  width="145" height="145">
                    </div>
                    <ul class="rating">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <!-- <li><i class="fas fa-star"></i></li> -->
                    </ul>
                    <p class="fixed-height" [innerHTML]="'PRICES.TEXT7' | translate"></p>
                    <div class="bar"></div>
                    <h3 class="main-color larger-font">240&#8364;</h3>
                    <span>{{'PRICES.PER_DAY'| translate}}</span>
                </div>‚
            </div>
        </div>
    </div>
</section>