<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/">
        <img src="assets/img/logo.png" alt="logo">
        <img src="assets/img/logo2.png" alt="logo">
    </a>
    <div class="lang-select">
        <a class="default-btn shrink" (click)="useLanguage('en')">ENG</a>
        <a class="default-btn shrink" (click)="useLanguage('de')">DE</a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">{{ 'MAIN_MENU.HOME' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link" href="#about">{{ 'MAIN_MENU.ABOUT' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link" href="#details">{{ 'MAIN_MENU.DETAILS' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link" href="#gallery">{{ 'MAIN_MENU.GALLERY' | translate }}</a></li>
            <!-- <li class="nav-item"><a class="nav-link" href="#agent">Agent</a></li> -->
            <li class="nav-item"><a class="nav-link" href="#feedback">{{ 'MAIN_MENU.PRICES' | translate }}</a></li>
            <!-- <li class="nav-item"><a class="nav-link" href="#services">Services</a></li> -->
            <li class="nav-item"><a class="nav-link" href="#contact">{{ 'MAIN_MENU.CONTACT' | translate }}</a></li>
        </ul>
    </div>
</nav>