<section class="floor-plans-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Floor Plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="tab">
            <ul class="tabs">
                <li><a href="#">
                    Floor 1
                </a></li>
                
                <li><a href="#">
                    Floor 2
                </a></li>

                <li><a href="#">
                    Floor 3
                </a></li>

                <li><a href="#">
                    Floor 4
                </a></li>

                <li><a href="#">
                    Floor 5
                </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 content">
                            <div class="tabs_item_content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>

                                <ul>
                                    <li>Floor No <span>1</span></li>
                                    <li>Rooms <span>4</span></li>
                                    <li>Total Area <span>311.50 sqft</span></li>
                                    <li>Bathroom <span>Yes</span></li>
                                    <li>Windows <span>4</span></li>
                                </ul>

                                <a href="#" class="default-btn">Schedule a Visit</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 image">
                            <div class="tabs_item_image">
                                <img src="assets/img/floor-img1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 image">
                            <div class="tabs_item_image">
                                <img src="assets/img/floor-img2.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 content">
                            <div class="tabs_item_content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>

                                <ul>
                                    <li>Floor No <span>2</span></li>
                                    <li>Rooms <span>3</span></li>
                                    <li>Total Area <span>301.50 sqft</span></li>
                                    <li>Bathroom <span>Yes</span></li>
                                    <li>Windows <span>3</span></li>
                                </ul>

                                <a href="#" class="default-btn">Schedule a Visit</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 content">
                            <div class="tabs_item_content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>

                                <ul>
                                    <li>Floor No <span>3</span></li>
                                    <li>Rooms <span>4</span></li>
                                    <li>Total Area <span>311.50 sqft</span></li>
                                    <li>Bathroom <span>Yes</span></li>
                                    <li>Windows <span>4</span></li>
                                </ul>

                                <a href="#" class="default-btn">Schedule a Visit</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 image">
                            <div class="tabs_item_image">
                                <img src="assets/img/floor-img3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 image">
                            <div class="tabs_item_image">
                                <img src="assets/img/floor-img4.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 content">
                            <div class="tabs_item_content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>

                                <ul>
                                    <li>Floor No <span>4</span></li>
                                    <li>Rooms <span>4</span></li>
                                    <li>Total Area <span>311.50 sqft</span></li>
                                    <li>Bathroom <span>Yes</span></li>
                                    <li>Windows <span>4</span></li>
                                </ul>

                                <a href="#" class="default-btn">Schedule a Visit</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 content">
                            <div class="tabs_item_content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>

                                <ul>
                                    <li>Floor No <span>5</span></li>
                                    <li>Rooms <span>4</span></li>
                                    <li>Total Area <span>311.50 sqft</span></li>
                                    <li>Bathroom <span>Yes</span></li>
                                    <li>Windows <span>4</span></li>
                                </ul>

                                <a href="#" class="default-btn">Schedule a Visit</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 image">
                            <div class="tabs_item_image">
                                <img src="assets/img/floor-img5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>