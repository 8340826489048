import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/common/about/about.component';
import { AgentComponent } from './components/common/agent/agent.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { GalleryComponent } from './components/common/gallery/gallery.component';
import { MapsComponent } from './components/common/maps/maps.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { PropertyDetailsComponent } from './components/common/property-details/property-details.component';
import { ServicesComponent } from './components/common/services/services.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { PropertyDetailsTwoComponent } from './components/common/property-details-two/property-details-two.component';
import { FloorPlansComponent } from './components/common/floor-plans/floor-plans.component';
import { SuccessComponent } from './success/success.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastrModule } from 'ngx-toastr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AgentComponent,
    ContactComponent,
    FeedbackComponent,
    FooterComponent,
    GalleryComponent,
    MapsComponent,
    NavbarComponent,
    PreloaderComponent,
    PropertyDetailsComponent,
    ServicesComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    FeaturesComponent,
    PropertyDetailsTwoComponent,
    FloorPlansComponent,
    SuccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
