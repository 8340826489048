<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-vp1.jpg" alt="about-image">
                    <img src="assets/img/layer.png" class="back-img1" alt="layer">
                    <img src="assets/img/layer.png" class="back-img2" alt="layer">
                    <img src="assets/img/about-vp2.jpg" class="circle-img shadow-sm" alt="circle-image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <h3>{{ 'ABOUT.TITLE' | translate }}</h3>
                    <p>{{ 'ABOUT.INTRO.TEXT' | translate }}</p>

                    <div class="about-inner">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-car"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.PARKING.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.PARKING.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-swimming-pool"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.SWIMMING_POOL.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.SWIMMING_POOL.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-champagne-glasses"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.CANOPY.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.CANOPY.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-bed"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.BEDROOMS.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.BEDROOMS.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-building"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.LIVING_ROOM.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.LIVING_ROOM.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-dog"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.PET_FRIENDLY.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.PET_FRIENDLY.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-store"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.MARKETS.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.MARKETS.TEXT' | translate }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="single-inner-box">
                                    <div class="icon">
                                        <i class="fas fa-spa"></i>
                                    </div>
                                    <h4>{{ 'ABOUT.SAUNA.TITLE' | translate }}</h4>
                                    <p>{{ 'ABOUT.SAUNA.TEXT' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>