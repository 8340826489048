<section class="features-area pt-100 pb-70 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fas fa-tree"></i>
                    </div>
                    <h3>Nature All Around You</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-angle-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="fas fa-car"></i>
                    </div>
                    <h3>Get Free Parking Place</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-angle-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features">
                    <div class="icon">
                        <i class="fab fa-expeditedssl"></i>
                    </div>
                    <h3>24/7 Security</h3>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>