import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    recaptchaToken: string;

    contactForm: FormGroup;
    siteKey = "6LeBRmYlAAAAABFn56A-FQN-8lCUGkXpz1LcVBzx";

    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            msg_subject: ["", Validators.required],
            message: ["", Validators.required],
            recaptcha: ["", Validators.required],
        });
    }

    get f() {
        return this.contactForm.controls;
    }

    handleReset() {
        // console.log("reset");
        this.contactForm.reset();
    }

    handleExpire() {
        // console.log("expire");
        this.contactForm.reset();
    }

    handleLoad() {
        // console.log("load");
    }

    handleSuccess(event: any) {
        this.recaptchaToken = event;
    }

    onSubmit() {
        if (this.contactForm.invalid || !this.recaptchaToken) {
            console.log("invalid form or token");
            this.toastr.error(
                "Please fill in all fields and verify that you are not a robot.",
                "Error"
            );
            return;
        }

        const params = new HttpParams()
            .set("name", this.contactForm.controls.name.value)
            .set("email", this.contactForm.controls.email.value)
            .set("msg_subject", this.contactForm.controls.msg_subject.value)
            .set("message", this.contactForm.controls.message.value)
            .set("g-recaptcha-response", this.contactForm.controls.recaptcha.value);

        this.http
            .get("https://villa-paula.eu/submit.php", { params })
            .subscribe(
                (response) => {
                // handle response from server
                console.log("response", response);
                this.toastr.success(
                    "Your message has been sent successfully.",
                    "Success"
                );
            },
            (error) => {
                // handle error from server
                console.log("error", error);
                this.toastr.error(
                    "An error occurred while sending your message.",
                    "Error"
                );
            }
            );
    }
}
