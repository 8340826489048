<div id="home" class="main-banner item-bg4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>Welcome to Holsworthy</span>
                            <h1>Moderns. Luxury. Safety</h1>
                            <p><i class="icofont-location-pin"></i> 210-2750 Quadra Street Victoria, New York, NY, USA</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12 offset-lg-1 offset-md-0">
                        <div class="visit-form">
                            <form>
                                <div class="form-group mb-3">
                                    <label>Your Name</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group mb-3">
                                    <label>Your Email</label>
                                    <input type="email" class="form-control">
                                </div>

                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea cols="30" rows="4" class="form-control"></textarea>
                                </div>

                                <button type="submit" class="default-btn">Schedule a Visit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-features></app-features>

<app-about></app-about>

<app-property-details-two></app-property-details-two>

<app-gallery></app-gallery>

<app-floor-plans></app-floor-plans>

<app-agent></app-agent>

<app-feedback></app-feedback>

<app-services></app-services>

<app-maps></app-maps>

<app-contact></app-contact>