<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="fas fa-search-location"></i>
                    </div>
                    <h3>Property Management</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="fas fa-chart-pie"></i>
                    </div>
                    <h3>Capital Care</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="far fa-chart-bar"></i>
                    </div>
                    <h3>Financial Reportign</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="fas fa-seedling"></i>
                    </div>
                    <h3>Business Development</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Finance Real Estate</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="fas fa-money-bill"></i>
                    </div>
                    <h3>Recover Asset Value</h3>
                    <div class="bar"></div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#">Read More <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>