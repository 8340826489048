<section id="home" class="home-area">
    <div class="swiper-container home-slides">
        <div class="swiper-wrapper">
            <div class="swiper-slide main-banner item-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h1>South Grafield Street for sale <span>$6,575,000</span></h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                <a href="#" class="default-btn">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-slide main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h1>South Grafield Street for sale <span>$6,575,000</span></h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                <a href="#" class="default-btn">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-slide main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <h1>South Grafield Street for sale <span>$6,575,000</span></h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                <a href="#" class="default-btn">View Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="swiper-pagination"></div>
    </div>
</section>

<app-about></app-about>

<app-property-details></app-property-details>

<app-gallery></app-gallery>

<app-agent></app-agent>

<app-feedback></app-feedback>

<app-services></app-services>

<app-maps></app-maps>

<app-contact></app-contact>