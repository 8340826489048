<section id="gallery" class="photo-gallery-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2>{{'GALLERY.TITLE' | translate}}</h2>
            <p>{{'GALLERY.TEXT' | translate}}</p>
        </div>
    </div>

    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1330.jpg" class="popup-btn"><img src="assets/img/gallery-vp1330.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="row m-0">
                <div class="col-lg-6 col-sm-6 col-md-6 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1290.jpg" class="popup-btn"><img src="assets/img/gallery-vp1290.jpg" alt="image"></a>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1166.jpg" class="popup-btn"><img src="assets/img/gallery-vp1166.jpg" alt="image"></a>
                    </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1269.jpg" class="popup-btn"><img src="assets/img/gallery-vp1269.jpg" alt="image"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1238.jpg" class="popup-btn"><img src="assets/img/gallery-vp1238.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1095.jpg" class="popup-btn"><img src="assets/img/gallery-vp1095.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1312.jpg" class="popup-btn"><img src="assets/img/gallery-vp1312.jpg" alt="image"></a>
            </div>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1240.jpg" class="popup-btn"><img src="assets/img/gallery-vp1240.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="row m-0">
                <div class="col-lg-6 col-sm-6 col-md-6 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1353.jpg" class="popup-btn"><img src="assets/img/gallery-vp1353.jpg" alt="image"></a>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1144.jpg" class="popup-btn"><img src="assets/img/gallery-vp1144.jpg" alt="image"></a>
                    </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <div class="photo-gallery-item">
                        <a href="assets/img/img_1342.jpg" class="popup-btn"><img src="assets/img/gallery-vp1342.jpg" alt="image"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1292.jpg" class="popup-btn"><img src="assets/img/gallery-vp1292.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1250.jpg" class="popup-btn"><img src="assets/img/gallery-vp1250.jpg" alt="image"></a>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6 p-0">
            <div class="photo-gallery-item">
                <a href="assets/img/img_1355.jpg" class="popup-btn"><img src="assets/img/gallery-vp1355.jpg" alt="image"></a>
            </div>
        </div>
    </div>
</section>