<section id="agent" class="agent-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="agent-image">
                    <img src="assets/img/agent.jpg" alt="agent"> 
                    <img src="assets/img/layer.png" class="back-img1" alt="layer">
                    <img src="assets/img/layer.png" class="back-img2" alt="layer">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="agent-content">
                    <div class="section-title">
                        <h2>The Agent</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="agent-info">
                        <h3>Bryan Adams</h3>
                        <span>Sales Manager</span>
                    </div>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul class="agent-contact-info">
                        <li>
                            <span>Office:</span>
                            <i class="fas fa-phone-alt"></i>
                            <a href="#">+444 157 895 4578</a>
                        </li>
                        <li>
                            <span>Home:</span>
                            <i class="fas fa-fax"></i>
                            <a href="#">+444 157 895 4578</a>
                        </li>
                        <li>
                            <span>Email:</span>
                            <i class="far fa-envelope"></i>
                            <a href="#">hello@aurick.com</a>
                        </li>
                        <li>
                            <span>Fax:</span>
                            <i class="fas fa-envelope-open-text"></i>
                            <a href="#">+444 157 895 4578</a>
                        </li>
                    </ul>

                    <ul class="social">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    </ul>

                    <a href="#" class="default-btn">Contact Me</a>
                </div>
            </div>
        </div>
    </div>
</section>