<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{'CONTACT.TITLE' | translate}}</h2>
            <p>
                {{'CONTACT.text' | translate}}
            </p>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-box">
                    <div class="agent-contact">
                        <div class="image">
                            <img
                                src="assets/img/contact-owner.jpg"
                                alt="image"
                            />
                        </div>
                        <div class="content">
                            <h3>Mirjana Perić</h3>
                            <span>{{'CONTACT.OWNER.TITLE' | translate}}</span>
                            <p>
                                {{'CONTACT.OWNER.TEXT' | translate}}
                            </p>
                        </div>
                    </div>
                    <ul class="contact-info">
                        <li>
                            <i class="fas fa-phone"></i>
                            <a href="tel:+385994104421">+385 99 4104 421</a>
                        </li>
                        <li>
                            <i class="fas fa-phone"></i
                            ><a href="tel:+381692505974">+381 69 2505 974</a>
                        </li>
                        <li>
                            <i class="fas fa-brands fa-viber size"></i>
                            <a href="viber://chat?number=381692505974"
                                >+381 69 2505 974</a
                            >
                        </li>
                        <li>
                            <i
                                class="fas fa-brands fa-square-whatsapp size"
                            ></i>
                            <a href="https://wa.me/381692505974"
                                >+381 69 2505 974</a
                            >
                        </li>
                        <li>
                            <i class="fas fa-envelope-open"></i>
                            <a href="mailto:mirjana.peric947@gmail.com"
                                >mirjana.peric947@gmail.com</a
                            >
                        </li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <form id="contactForm" (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group mb-3">
                              <label>{{'CONTACT.CONTACT_FORM.NAME.TITLE' | translate}}</label>
                              <input type="text" class="form-control" formControlName="name" [placeholder]="'CONTACT.CONTACT_FORM.NAME.PLACEHOLDER' | translate">
                              <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                <small class="text-danger" *ngIf="contactForm.get('name').errors.required">{{'CONTACT.CONTACT_FORM.NAME.ERROR' | translate}}</small>
                              </div>
                            </div>
                          </div>
                  
                          <div class="col-lg-12 col-md-6">
                            <div class="form-group mb-3">
                              <label>{{'CONTACT.CONTACT_FORM.EMAIL.TITLE' | translate}}</label>
                              <input type="email" class="form-control" formControlName="email" [placeholder]="'CONTACT.CONTACT_FORM.EMAIL.PLACEHOLDER' | translate">
                              <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                <small class="text-danger" *ngIf="contactForm.get('email').errors.required">{{'CONTACT.CONTACT_FORM.EMAIL.ERROR' | translate}}</small>
                                <small class="text-danger" *ngIf="contactForm.get('email').errors.email">{{'CONTACT.CONTACT_FORM.EMAIL.ERROR2' | translate}}</small>
                              </div>
                            </div>
                          </div>
                  
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                              <label>{{'CONTACT.CONTACT_FORM.SUBJECT.TITLE' | translate}}</label>
                              <input type="text" class="form-control" formControlName="msg_subject" [placeholder]="'CONTACT.CONTACT_FORM.SUBJECT.PLACEHOLDER' | translate">
                              <div *ngIf="contactForm.get('msg_subject').invalid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)">
                                <small class="text-danger" *ngIf="contactForm.get('msg_subject').errors.required">{{'CONTACT.CONTACT_FORM.SUBJECT.ERROR' | translate}}</small>
                              </div>
                            </div>
                          </div>
                  
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                              <label>{{'CONTACT.CONTACT_FORM.MESSAGE.TITLE' | translate}}</label>
                              <textarea formControlName="message" class="form-control" cols="30" rows="4" [placeholder]="'CONTACT.CONTACT_FORM.MESSAGE.PLACEHOLDER' | translate"></textarea>
                              <div *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)">
                                <small class="text-danger" *ngIf="contactForm.get('message').errors.required">{{'CONTACT.CONTACT_FORM.MESSAGE.ERROR' | translate}}</small>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 col-md-12">
                            <ngx-recaptcha2
                                #captchaElem
                                [siteKey]="siteKey"
                                (reset)="handleReset()"
                                (expire)="handleExpire()"
                                (load)="handleLoad()"
                                (success)="handleSuccess($event)"
                                [useGlobalDomain]="false"
                                formControlName="recaptcha"
                            >
                            </ngx-recaptcha2>
                            <button type="submit" class="default-btn" [disabled]="contactForm.invalid">{{'CONTACT.CONTACT_FORM.MESSAGE.BUTTONS.SEND_MESSAGE' | translate}}</button>
                          </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
