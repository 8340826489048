<section id="details" class="property-details-area-two">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="video-image">
                    <img src="assets/img/vp_logo.jpg" alt="image">

                    <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="property-details-info">
                    <div class="property-details">
                        <h3>{{ 'DETAILS.PROPERTY_DETAILS.TITLE' | translate }}</h3>

                        <ul>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.ACCOMMODATION' | translate }}: <span>{{ 'DETAILS.PROPERTY_DETAILS.ACCOMODATION_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.HEATED_POOL' | translate }}: <span>32 m<sup>2</sup></span></li>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.BEDROOMS' | translate }}: <span>3</span></li>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.HOUSE_AREA' | translate }}: <span>120 m<sup>2</sup></span></li>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.PARKING_AREA' | translate }}: <span>120 m<sup>2</sup></span></li>
                            <li>{{ 'DETAILS.PROPERTY_DETAILS.CANOPY_AREA' | translate }}: <span>40 m<sup>2</sup></span></li>
                        </ul>
                    </div>

                    <div class="additional-details">
                        <h3>{{ 'DETAILS.ADDITIONAL_DETAILS.TITLE' | translate }}</h3>
                        
                        <ul>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.COOLING' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.COOLING_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.CONSTRUCTION_TYPE' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.CONSTRUCTION_TYPE_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.FIREPLACE_LOCATION' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.FIREPLACE_LOCATION_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.ROOF' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.ROOF_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.FIREPLACE' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.FIREPLACE_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.LOT_AREA' | translate }}: <span>280 m<sup>2</sup></span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.FEATURES' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.FEATURES_DESC' | translate }}</span></li>
                            <li>{{ 'DETAILS.ADDITIONAL_DETAILS.ROAD_SURFACE' | translate }}: <span>{{ 'DETAILS.ADDITIONAL_DETAILS.ROAD_SURFACE_DESC' | translate }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>