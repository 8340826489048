<div id="home" class="main-banner video-banner">
    <video loop [muted]="'muted'" autoplay poster="#" class="video-background">
        <source src="assets/video/Home.mp4" type="video/mp4">
    </video>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>{{'HOME.WELCOME' | translate}} <br><span>{{'HOME.FOR_RENT' | translate}}</span></h1>
                    <p>+385 99 4104 421 <br>
                        +381 69 2505 974
                    </p>

                    <a href="#about" class="default-btn">{{'HOME.VIEW_DETAILS' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about></app-about>

<app-property-details-two></app-property-details-two>

<app-gallery></app-gallery>

<div class="spacer"></div>

<!-- <app-agent></app-agent> -->

<app-feedback></app-feedback>

<div class="spacer"></div>

<!-- <app-services></app-services> -->

<app-maps></app-maps>

<app-contact></app-contact>